<template>
  <div>
    <div class="crumbs" v-cloak>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i>
          {{ id ? "编辑文章" : "添加文章" }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-form ref="form" :model="rowData" label-width="140px">
          <el-form-item label="文章名称:">
            <el-input
              v-model="rowData.name"
              placeholder="请输入文章名称"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="渠道:">
                <el-select
                  v-model="rowData.clientId"
                  @change="clientChange"
                  placeholder="请选择"
                  class="mr10"
                >
                  <el-option
                    :key="item.id"
                    v-for="item in channelDatas"
                    :label="item.description"
                    :value="item.clientId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型:">
                <el-select
                  v-model="rowData.categoryId"
                  :placeholder="rowData.clientId ? '请选择' : '请先选择渠道'"
                  class="mr10"
                >
                  <el-option
                    :key="item.id"
                    v-for="item in categoryArr"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="URL:" v-if="rowData.clientId == 100019">
            <el-input
              v-model="rowData.headUrl"
              placeholder="单词之间用-隔开"
            ></el-input>
          </el-form-item>
          <el-form-item label="封面:" v-if="rowData.clientId == 100019">
            <el-upload
              class="up-btn"
              :http-request="uploadCover"
              action="String"
              :beforeUpload="beforeRemove"
              multiple
              :show-file-list="false"
            >
              <el-button type="primary">点击上传</el-button>
            </el-upload>
            <div class="person-img">
              <el-image
                class="pre"
                :src="rowData.banner"
                :preview-src-list="[rowData.banner]"
              ></el-image>
            </div>
          </el-form-item>
          <el-row v-if="rowData.clientId != 100019">
            <el-col :span="12">
              <el-form-item label="文章来源:">
                <el-input
                  v-model="rowData.source"
                  placeholder="请输入文章来源"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="文章来源网址:">
                <el-input
                  v-model="rowData.sourceUrl"
                  placeholder="选填"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <!-- <el-col :span="12">
              <el-form-item label="SEO标题:">
                <el-input v-model="rowData.name" placeholder></el-input>
              </el-form-item>
            </el-col>-->
            <el-col :span="12">
              <el-form-item label="SEO描述:">
                <el-input v-model="rowData.description" placeholder></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="SEO关键字:">
            <el-input
              v-model="rowData.keywords"
              type="textarea"
              :rows="3"
              maxlength="100"
              placeholder="请输入关键字，英文逗号隔开"
            ></el-input>
          </el-form-item>
          <el-form-item label="标签:" v-if="rowData.clientId != 100019">
            <div class="choose-list" v-if="dynamicTags.length > 0">
              <el-tag
                :key="tag"
                v-for="tag in dynamicTags"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)"
                >{{ tag.name }}</el-tag
              >
            </div>
            <div v-if="rowData.clientId">
              <div class="tag-list" v-if="labelArr.length > 0">
                <el-button
                  size="small"
                  round
                  v-for="(item, index) in labelArr"
                  :key="index"
                  @click="chooseTag(item)"
                  :class="filterClass(item) ? 'active' : ''"
                  >{{ item.name }}</el-button
                >
              </div>
              <div v-else>该渠道下无标签</div>
            </div>
            <div v-else>请先选择渠道</div>
          </el-form-item>
          <el-form-item label="文章内容:">
            <TEditor ref="editor" v-model="content" />
          </el-form-item>
          <div class="check">
            <el-form-item label="是否启用">
              <el-checkbox v-model="rowData.isEnable">启用</el-checkbox>
            </el-form-item>
          </div>
          <el-form-item label="排序:">
            <el-input
              v-model.number="rowData.sort"
              type="number"
              placeholder="数字越小，越靠前"
            ></el-input>
          </el-form-item>
          <el-form-item label="阅读量:">
            <el-input
              v-model.number="rowData.readCount"
              type="number"
              placeholder="数字越小，越靠前"
            ></el-input>
          </el-form-item>
        </el-form>
        <span class="dialog-footer">
          <el-button type="primary" @click="saveEdit">保 存</el-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  clientConfig,
  seoCategoryList,
  seoLabelList,
  seoArticleUpdate,
  seoArticleGetById,
  getUploadMh,
} from "../../api/index";

export default {
  name: "docEdit",
  data() {
    return {
      content: "",
      rowData: {
        isEnable: true,
        name: "",
        content: "",
        headUrl: "",
        banner: "",
        categoryId: "",
      },
      dynamicTags: [],
      id: "",
      channelDatas: [],
      categoryArr: [], // 类型
      labelArr: [],
    };
  },
  created() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getById();
    } else {
      const randomNumber = Math.floor(Math.random() * (5000 - 100 + 1)) + 100;
      this.rowData.readCount = randomNumber;
    }
    this.channelArray();
  },
  watch: {},
  methods: {
    // 上传图片校验
    beforeRemove(file, fileList) {
      console.log(fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "webp" ||
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "svg" ||
        testmsg === "WEBP" ||
        testmsg === "SVG";
      const isLt50M = file.size / 1024 / 1024 < 100;
      console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 100MB!",
          type: "error",
        });
        return false;
      }
      return extension && isLt50M;
    },
    uploadCover(params) {
      const file = params.file;
      console.log(params, file, "888");
      var formdata = new FormData();
      formdata.append("file", file);
      getUploadMh(formdata).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.rowData.banner = res?.data?.url || "";
        } else {
          this.$message({
            message: "上传失败",
            type: "error",
          });
        }
      });
    },
    getById() {
      seoArticleGetById(this.id).then((res) => {
        console.log("res", res);
        if (res.code === 0) {
          this.rowData = {
            ...res.data,
            categoryId: parseInt(res.data.categoryId),
          };

          this.content = res.data.content;
          this.$refs.editor.initTiny(this.content); // 重新设置编辑器内容
          this.getData(res.data.clientId);
          this.getTagData(res.data.clientId);
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
    },
    saveEdit() {
      if (this.rowData.name == "") {
        this.$message({
          message: "请输入文章名称",
          type: "error",
        });
        return false;
      }
      if (!this.rowData.clientId) {
        this.$message({
          message: "请选择渠道",
          type: "error",
        });
        return false;
      }
      if (!this.rowData.categoryId) {
        this.$message({
          message: "请选择类型",
          type: "error",
        });
        return false;
      }
      if (this.rowData.clientId == 100019 && !this.rowData.banner) {
        this.$message({
          message: "请输入封面",
          type: "error",
        });
        return false;
      }
      if (!this.rowData.description) {
        this.$message({
          message: "请输入SEO描述",
          type: "error",
        });
        return false;
      }
      if (!this.rowData.keywords) {
        this.$message({
          message: "请输入SEO关键字",
          type: "error",
        });
        return false;
      }
      if (this.dynamicTags.length < 1 && this.rowData.clientId != 100019) {
        this.$message({
          message: "请选择标签",
          type: "error",
        });
        return false;
      }
      if (this.rowData.clientId == 100019 && !this.rowData.headUrl) {
        this.$message({
          message: "请输入URL",
          type: "error",
        });
        return false;
      }
      console.log("dynamicTags", this.dynamicTags);
      if (this.dynamicTags) {
        const idArray = this.dynamicTags.map((item) => item.id);
        this.rowData.labelId = idArray.join(",");
      }
      this.rowData.content = this.$refs.editor.getText(); // 富文本重新赋值
      console.log("this", this.rowData);
      if (!this.rowData.content || this.rowData.content == "<p>请输入</p>") {
        this.$message({
          message: "请输入文章内容",
          type: "error",
        });
        return false;
      }

      // if (!this.rowData.readCount) {
      //   this.$message({
      //     message: "请输入阅读量",
      //     type: "error"
      //   });
      //   return false;
      // }
      const timestamp = new Date().getTime();
      if (this.rowData.clientId != "100019" && !this.rowData.id) {
        this.rowData.headUrl = timestamp;
      }
      seoArticleUpdate(this.rowData)
        .then((res) => {
          console.log("res", res);
          if (res.code === 0) {
            console.log(2);
            let str = this.rowData.id ? "修改" : "添加";
            this.$message({
              type: "success",
              message: str + "成功!",
            });
            this.rowData.name = "";
            this.rowData.content = "";
            this.rowData.sort = "";
            this.content = "";
            this.rowData.readCount = "";
            const randomNumber =
              Math.floor(Math.random() * (5000 - 100 + 1)) + 100;
            this.rowData.readCount = randomNumber;
            this.$refs.editor.initTiny("");
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        })
        .catch((res) => {
          this.$message({
            message: res?.data?.message || "保存失败",
            type: "error",
          });
          console.log("res", res);
        });
    },
    clientChange(e) {
      this.rowData.categoryId = "";
      this.dynamicTags = [];
      this.categoryArr = [];
      if (e) {
        this.getData(e);
        this.getTagData(e);
      }
    },
    getData(clientId) {
      let params = {
        clientId: clientId, // 行业
        pageIndex: 1,
        pageSize: 100,
        name: "",
        isEnable: true,
      };
      seoCategoryList(params).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.categoryArr = res.data.data;
          if (this.categoryArr.length < 1) {
            this.rowData.categoryId = "";
          }
        } else {
          this.$message({
            message: "类型获取失败",
            type: "error",
          });
        }
      });
    },
    getTagData(clientId) {
      let params = {
        name: "", //数字则查id，文字则查名称，或者标题
        clientId: clientId, // 行业
        pageIndex: 1,
        pageSize: 1000,
        isEnable: true,
      };
      if (params.isEnable == "") {
        delete params.isEnable;
      }
      seoLabelList(params).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.labelArr = res.data.data;
          if (this.labelArr.length < 1) {
            this.dynamicTags = [];
          }
          if (this.id && this.rowData.labelId && this.labelArr.length > 0) {
            const array = this.labelArr;
            const idString = this.rowData.labelId;

            // 使用 split 函数将字符串转换为数组
            const idArray = idString.split(",").map(Number);

            const filteredArray = array.filter((item) =>
              idArray.includes(item.id)
            );
            this.dynamicTags = filteredArray;
          }
        } else {
          this.$message({
            message: "标签获取失败",
            type: "error",
          });
        }
      });
    },
    channelArray() {
      clientConfig({
        type: "SEO",
      }).then((res) => {
        if (res.code == 0) {
          this.channelDatas = res.data;
          this.channelDatas.unshift({
            description: "全部",
            clientId: "",
          });
        }
        console.log("res", res);
      });
    },
    filterClass(tag) {
      let has = this.dynamicTags.filter((res) => {
        return res.id == tag.id;
      });
      return has.length > 0;
    },
    backFn() {
      this.$router.back();
    },
    chooseTag(item) {
      let has =
        this.dynamicTags.filter((res) => {
          return res.id == item.id;
        }) || [];
      if (has.length < 1) {
        this.dynamicTags.push(item);
      }
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
  },
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.w80 {
  width: 98px;
}
.ml10 {
  margin-left: 10px;
}
.btns {
  margin-bottom: 10px;
}
.check {
  display: flex;
}
.choose-list {
  border: 1px solid #eee;
  display: inline-block;
  padding: 2px 6px;
}
.choose-list .el-tag {
  margin-right: 6px;
}
.dialog-footer {
  display: block;
  text-align: center;
  padding: 50px 0;
}
.tag-list .active {
  color: #3a8ee6;
  border-color: #3a8ee6;
  outline: 0;
  background-color: #ecf5ff;
}

.up-btn ::v-deep .el-upload {
  width: 98px;
  height: 40px;
  border: 0 !important;
  background-color: #409eff;
  line-height: 20px;
}
.up-btn >>> .el-button--small {
  width: 80px;
  height: 32px;
  border: 0 !important;
  background-color: #409eff;
}
.pre {
  width: 100px;
  height: 100px;
}
.up-btn .el-upload--text {
  border: 0;
  width: 100px;
  height: 40px;
}
</style>
